<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { VideoExtend, QuillVideoWatch, container } from 'quill-video-image-module'
import video from 'quill-video-image-module/video'
import { ACCESS_TOKEN } from '../../../store/mutation-types'

Quill.register(video, true)
Quill.register('modules/VideoExtend', VideoExtend)
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  methods: {
    updateImage() {
      const token = localStorage.getItem(ACCESS_TOKEN).replace(/"/g, '')
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'text/img'
      input.oninput = () => {
        const form = new FormData()
        form.append('multipartFile', input.files[0])
        fetch('/api/common/upload', {
          method: 'POST',
          body: form,
          headers: {
            'X-Application-name': 'app',
            Authorization: `bearer ${token}`
          }
        })
          .then(e => e.json())
          .then(e => {
            let length = this.$refs.myQuillEditor.quill.getSelection().index
            this.$refs.myQuillEditor.quill.insertEmbed(length, 'image', e.fileUrl)
            this.$refs.myQuillEditor.quill.setSelection(length + 1)
          })
      }
      input.click()
    },
    getOption() {
      const token = localStorage.getItem(ACCESS_TOKEN).replace(/"/g, '')

      let that = this
      return {
        modules: {
          VideoExtend: {
            loading: true,
            name: 'multipartFile',
            size: 100, // 可选参数 视频大小，单位为M，1M = 1024kb
            action: '/api/common/upload', // 视频上传接口
            headers: xhr => {
              return {
                'X-Application-name': 'app',
                Authorization: `bearer ${token}`
              }
            },
            response: res => {
              return res.fileUrl + '.mp4'
            },
            sizeError: () => {
              alert('视频不能大于100M')
            }
          },
          toolbar: {
            container: container,
            handlers: {
              video: function name() {
                QuillVideoWatch.emit(this.quill.id)
              },
              image: function(value) {
                if (value) {
                  that.updateImage()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }
    },
    onChange(data) {
      this.$emit('change', data.html)
    }
  },
  render() {
    return (
      <div class="form-rich">
        <client-only>
          <quillEditor
            ref="myQuillEditor"
            value={this.value}
            onChange={this.onChange}
            options={this.getOption()}
            style={{ height: '550px' }}
          />
        </client-only>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-rich {
  height: 600px;
  /deep/.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }
  /deep/.ql-container.ql-snow {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }
  /deep/.ql-picker-label {
    display: flex;
    align-items: center;
  }
}
</style>
