<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  methods: {
    onChange(e) {
      if (this.item.onChange) {
        this.item.onChange(e)
      }
      this.$emit('change', String(e))
    }
  },
  render() {
    return (
      <div class="form-input">
        <Input
          props={this.item.props}
          disabled={this.mode == 'detail' || this.item.disabled}
          value={this.value}
          placeholder={this.item.placeholder ? this.item.placeholder : '请输入' + (this.item.name || this.item.label)}
          onChange={e => this.onChange(e.target.value)}
          style={{ width: '100%', ...this.item.styles }}
        />
        {this.item.desc && <div>{this.item.desc}</div>}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-input {
  display: flex;
  align-items: center;
  margin-top: 3px;
  div {
    margin-left: 10px;
    color: #a0a0a0;
    white-space: nowrap;
  }
}
</style>
