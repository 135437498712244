<!--
 * @Description: 
 * @Date: 2021-05-09 18:13:17
 * @LastEditors: tonglin.li
 * @LastEditTime: 2021-05-10 17:49:47
 * @FilePath: \ztesa-zsnc-admin\src\components\FormView\FormLabelGroup\index.vue
-->
<script>
import { Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    typeData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    typeData(newValue) {
      if (!this.init) {
        this.init = true
        this.$emit('change', newValue)
      }
    }
  },
  mounted() {
    this.$emit('change', this.typeData)
  },
  methods: {
    addorRemove(item, index) {
      item.select = item.select == '0' ? '1' : '0'
      this.$emit('change', this.typeData)
    },
    renderLabelItem(item, index) {
      return (
        <div class="label-group-item" key={item.value}>
          <Button
            onClick={() => this.addorRemove(item, index)}
            class="label-button"
            type={item.select == '1' ? 'primary' : 'default'}
          >
            {item.name}
          </Button>
        </div>
      )
    }
  },
  render() {
    return (
      <div class="label-group-main">
        {this.typeData.map((e, index) => {
          return this.renderLabelItem(e, index)
        })}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.label-group-main {
  display: flex;
  flex-wrap: wrap;
}
.label-group-item {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
</style>
