<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    getValue: {
      type: Function,
      default: null
    }
  },
  methods: {
    getTextValue(item) {
      if (item.value) {
        return item.value
      } else {
        return this.getValue(item)
      }
    },
    renderItem(item) {
      return (
        <div class="text-group-item" style={{ ...item.style, width: item.width }}>
          <div>
            <span style={{ marginRight: '10px' }}>{item.name}</span>:
            <span style={{ marginLeft: '10px' }}>{this.getTextValue(item)}</span>
          </div>
          {typeof item.right == 'function' && item.showRight && item.right(this.$createElement)}
        </div>
      )
    }
  },
  render() {
    return <div class="text-group-main">{this.value.map(e => this.renderItem(e))}</div>
  }
}
</script>
<style lang="less" scoped>
.text-group-main {
  display: flex;
  flex-wrap: wrap;
}
.text-group-item {
  width: 50%;
  color: #515a6e;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
