<script>
import { Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <Button
        props={this.item.props}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '3px',
          ...(this.item.props || {}).style
        }}
        {...this.item.props}
      >
        {this.item.label}
      </Button>
    )
  }
}
</script>
