<!--
 * @Description: 
 * @Date: 2021-05-06 15:22:34
 * @LastEditors: tonglin.li
 * @LastEditTime: 2021-05-10 18:07:43
 * @FilePath: \ztesa-zsnc-admin\src\components\FormView\FormDropDownInput\index.vue
-->
<script>
import { Dropdown, Menu, Input, Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: () => {}
    },
    typeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      setValueData: 'icon'
    }
  },
  watch: {
    value(a, b) {
      if (a) {
        this.setValueData = a
      }
    }
  },
  mounted() {},
  methods: {
    handleMenuClick(data) {
      this.setValueData = data.key
      if (this.item.onChange) {
        this.item.onChange(data)
      }
      this.$emit('change', data.key)
    }
  },
  render() {
    return (
      <a-dropdown-button>
        {this.setValueData}
        <a-menu slot="overlay" onClick={this.handleMenuClick}>
          <a-menu-item key={'icon'}>{'icon'}</a-menu-item>

          {this.typeData.map((e, i) => {
            return <a-menu-item key={e.value}>{e.value}</a-menu-item>
          })}
        </a-menu>
      </a-dropdown-button>
    )
  }
}
</script>
