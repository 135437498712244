<script>
import { TreeSelect } from 'ant-design-vue'
import { getAction } from '@/command/netTool'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      nodeId: 1
    }
  },
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.value || this.item.defaultValue)
    }
  },
  methods: {
    change(value, selectedOptions, extra) {
      if (this.item.onChange) {
        this.item.onChange(value, selectedOptions, extra)
      }
      this.$emit('change', value)
    }
  },
  render() {
    return (
      <TreeSelect
        props={this.item.props}
        v-model={this.value}
        style="width: 100%"
        treeData={this.item.typeData ?? this.typeData}
        treeCheckable={this.item.isCheckable}
        multiple={this.item.isMultiple}
        placeholder="请选择"
        searchPlaceholder={'关键字搜索'}
        showSearch={true}
        treeNodeFilterProp="title"
        allowClear
        dropdownStyle={{
          maxHeight: '435px'
        }}
        onChange={(value, label, extra) => this.change(value, label, extra)}
      />
    )
  }
}
</script>
