<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :media-query="query"
    :is-mobile="isMobile"
    :handle-media-query="handleMediaQuery"
    :handle-collapse="handleCollapse"
    :i18n-render="i18nRender"
    v-bind="settings"
    :collapsedButtonRender="collapsedButtonRender"
  >
    <template v-slot:menuHeaderRender>
      <div class="login-box2">
        <img src="../assets/logo.png" />
        <h1 v-show="collapsed == false">{{ title }}</h1>
      </div>
    </template>
    <template v-slot:headerContentRender>
      <div class="header-left">
        <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 16px; cursor: pointer" @click="reloadPage" />
        </a-tooltip>
        <a-breadcrumb v-if="showBread == true && !isMobile" :routes="routes" style="margin-left: 20px" />
      </div>
    </template>
    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <template v-slot:footerRender>
      <div></div>
    </template>
    <div>
      <multi-tab @remove="tabRemove" ref="multi" />
      <transition name="page-transition">
        <div style="margin-top: -20px">
          <!-- <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive> 
          <router-view v-if="!$route.meta.keepAlive"></router-view>-->
          <router-view />
        </div>
      </transition>
    </div>
  </pro-layout>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import Ads from '@/components/Other/CarbonAds'
import { asyncRouterMap } from '@/config/router.config.js'
import { getAction } from '@/command/netTool'
import { BasicLayout } from '@/layouts'

export default {
  name: 'basic-layout',
  components: {
    SettingDrawer,
    RightContent,
    Ads
  },
  data() {
    return {
      showBread: true,
      routes: [],
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      menus: [],
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        layout: defaultSettings.layout,
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        theme: defaultSettings.navTheme,
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,
        hideHintAlert: false,
        hideCopyButton: false
      },
      query: {},
      isMobile: false
    }
  },
  computed: {
    ...mapState({
      mainMenu: state => state.permission.addRouters
    })
  },
  created() {
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.$watch('$route', () => {
      this.initBreadRouters()
    })
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 1000) {
        this.showBread = false
      } else {
        if (this.showBread == false) {
          this.showBread = true
        }
      }
    })

    //********************************** */
    if (process.env.NODE_ENV === 'development') {
      this.getQiniuToken()
      const routes = asyncRouterMap.find(item => item.path === '/')
      this.menus = (routes && routes.children) || []
    } else {
      localStorage.setItem('VERSION', 'test')
      this.getMenu()
      this.getQiniuToken()
    }
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    tabRemove(currentEl) {
      // 页签关闭清除关闭页签缓存
      if (currentEl) {
        let keyName = currentEl.instances.default.$vnode.tag.split('-')[2]
        const index = currentEl.instances.default.$vnode.parent.componentInstance.keys.findIndex(
          item => item == keyName
        )
        if (index > -1) {
          let key = currentEl.instances.default.$vnode.parent.componentInstance.keys.splice(index, 1)
          let catchComp = currentEl.instances.default.$vnode.parent.componentInstance.cache
          delete catchComp[key]
          currentEl.instances.default.$destroy()
        }
      }
    },
    i18nRender,
    // 获取七牛token
    getQiniuToken() {
      getAction('/operation/common/getQnToken', {}, '/bpi').then(e => {
        localStorage.setItem('qiniu', JSON.stringify(e.data))
      })
    },
    getMenu(obj) {
      getAction('/api/menu/userTree?sysCode=FutureRural').then(e => {
        const button = {}
        this.menus = this.getChildren(e.data, obj, this.getSvgInit(), button)
        localStorage.setItem('button', JSON.stringify(button))
      })
    },
    getComponent(item) {
      if (item.component == 'RouteView') {
        return RouteView
      } else if (item.component == 'BasicLayout') {
        return BasicLayout
      } else {
        return () => import(`@/views/${item.path}`)
      }
    },
    getSvgInit() {
      let arr = require.context('../assets/icons/', true, /\.svg$/).keys()
      let obj = {}
      arr.forEach(e => {
        let a = e.substring(e.indexOf('./') + 2, e.indexOf('.svg'))
        obj[a] = require(`@/assets/icons/${a}.svg?inline`).default
      })
      return obj
    },
    getChildren(children = [], obj, objSvg, button, path) {
      const mChildren = children.filter(e => e.type !== '1')
      const mButton = children.filter(e => e.type == '1')
      if (mButton.length > 0) {
        button[path] = mButton
      }
      if (mChildren.length > 0) {
        return mChildren.map(e => {
          return {
            ...e,
            name: e.title,
            component: this.getComponent(e),
            hidden: e.display !== '0' ? true : false,
            meta: {
              title: e.name,
              subTitle: e.subtitle,
              keepAlive: e.keepAlive == '0' ? true : false,
              icon: objSvg[e.icon]
            },
            children: this.getChildren(e.children, obj, objSvg, button, e.path)
          }
        })
      } else {
        return null
      }
    },
    onMove() {
      if (this.collapsed) {
        this.collapsed = false
      }
    },
    listenMove() {
      if (document.body.clientWidth > 660) {
        // document.getElementsByClassName('ant-layout-sider-children')[0].removeEventListener('mousemove',this.onMove)
        // document.getElementsByClassName('ant-layout-sider-children')[0].addEventListener('mousemove', this.onMove)
      }
    },
    reloadPage() {
      this.$router.go(0)
    },
    initBreadRouters() {
      this.routes = this.$route.matched.map(e => ({
        path: e.path,
        breadcrumbName: e.meta.title
      }))
    },
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
    collapsedButtonRender(h, collapsed) {
      if (collapsed) {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      } else {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3-copy"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      }
    }
  }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
<style lang="less" scoped>
/deep/.router-link-active {
  .login-box {
    padding: 0;
    text-align: center;
    margin-left: -32px;
  }
  .login-h1 {
    font-family: YouSheBiaoTiHei;
    font-weight: 100;
    font-size: 32px;
    font-style: normal;
    margin-left: 0px;
  }
  .login-box2 {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
  }
}
.header-left {
  display: flex;
  align-items: center;
  height: 100%;
}
.pro-layout-ui {
  background: red;
}
.logo_box {
  width: 89%;
  height: auto;
  margin-left: -4%;
}
.logo_box2 {
  width: 115%;
  height: auto;
  margin-left: -25%;
}
/deep/.ant-breadcrumb {
  span {
    &:nth-last-child(1) {
      .ant-breadcrumb-link {
        font-weight: 600;
      }
    }
  }
}
</style>
