import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'
import { jumpLogin } from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import { getCookie } from '@/utils/cookie'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'register', 'registerResult'] // no redirect allowList
const loginRoutePath = '/user/login'
const defaultRoutePath = '/dashboard/workplace'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  /* has token */
  let access_token = getCookie('access_token')
  if (storage.get(ACCESS_TOKEN) || access_token) {
    if (access_token && [null, 'login'].includes(to.name) && !storage.get(ACCESS_TOKEN)) {
      storage.set(ACCESS_TOKEN, access_token.replace(/\"/g, ''), 7 * 24 * 60 * 60 * 1000)
      store.commit('SET_TOKEN', access_token)
      localStorage.setItem(ACCESS_TOKEN, access_token)
      router.push('/')
    }
    if (to.query.token) {
      localStorage.setItem(ACCESS_TOKEN, to.query.token)
    }
    if (to.path === loginRoutePath) {
      next()
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then(res => {
            const roles = res.result && res.result.role
            // generate dynamic router
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(() => {
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              jumpLogin()
              // localStorage.setItem('historyUrl', location.href)
              // next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        if (to.path !== '/personalsettingDetail') {
          getAction('/admin/my/queryPwdInfo', {}, '/bpi').then(r => {
            let { passwordModifyState } = r.data
            if (passwordModifyState == '2') {
              router.push({ name: 'personalsettingDetail' })
            } else {
              next()
            }
          })
        } else {
          next()
        }
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      if (to.query.token) {
        localStorage.setItem(ACCESS_TOKEN, to.query.token)
        next({ path: '/' })
      } else {
        jumpLogin()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
