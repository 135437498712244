import request from '@/utils/request'
import { Message } from 'ant-design-vue'
import { getAction, postAction, putAction } from '../command/netTool'
/**
 * 获取状态
 */
export const getState = function getState() {
  return {
    // 创建接口地址
    createUrl: '',
    // 列表接口地址
    listUrl: '',
    // 表格当前页
    current: 1,
    // 总数
    total: 10,
    // 表格列表数据
    records: [],
    // 表格查询参数
    params: {},
    // 表格附加查询参数
    paramsValue: {},
    // 详情
    detail: {},
    // 对应type类型
    type: {},
    // 类型接口地址
    typeUrl: '',
    // 判断当前是否是分页列表
    isPage: true,
    // 表格loading
    loading: false,
    // 列表
    list: [],
    // 每页显示数量
    pageSize: '10'
  }
}

/**
 * 获取列表
 */
export const getList = function getList(
  {
    url = this.listUrl,
    current = this.current,
    pageSize = this.pageSize,
    params = this.params,
    paramsValue = this.paramsValue,
    isPage = this.isPage
  } = {},
  host = '/api'
) {
  this.loading = true
  return new Promise(resolve => {
    getAction(
      url,
      {
        current,
        size: pageSize,
        ...params,
        ...paramsValue
      },
      host
    ).then(e => {
      this.loading = false
      this.listUrl = url
      this.current = current
      this.params = params
      this.paramsValue = paramsValue
      this.isPage = isPage
      this.total = e.data.total
      if (e.data.total !== undefined && e.data.total !== null) {
        this.records = e.data.records
      } else {
        this.records = e.data
      }
      resolve(e)
    })
  })
}

/**
 * 获取树结构
 */
export const getTree = function getTree({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then(e => {
        this.list = e.data
        resolve(e)
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 创建
 */
export const createDetail = function createDetail({ url, params, host = '/api' } = {}) {
  return new Promise((resolve, reject) => {
    this.loading = true
    postAction(url, params, host)
      .then(() => {
        Message.success('新建成功')
        history.back()
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 创建
 */
export const create = function create({ url, params, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('新建成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 删除
 */
export const del = function del({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'DELETE',
      params
    })
      .then(e => {
        if (e.code == 200) {
          if (this.total % this.pageSize === 1) {
            this.current--
          }
          Message.success('删除成功')
          isPost && getList.call(this)
          resolve()
        } else {
          Message.error(e.msg)
        }
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 删除
 */
export const delDetail = function delDetail({ url, params = {} } = {}) {
  request({
    url,
    method: 'DELETE',
    params
  }).then(e => {
    if (e.code == 200) {
      Message.success('删除成功')
    } else {
      Message.error(e.message)
    }
  })
}

/**
 * 删除
 */
export const delPost = function delPost({ url, params = {} } = {}) {
  return request({
    url,
    method: 'POST',
    params
  }).then(e => {
    if (e.code == 200) {
      Message.success('删除成功')
      getList.call(this)
    } else {
      Message.error(e.message)
    }
  })
}

export const delPostArr = function delPostArr({ url, params = {}, isPost = true, host = '/api' } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params, host)
      .then(() => {
        Message.success('删除成功')
        isPost && getList.call(this, _, host)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 删除Put
 */
export const delPut = function delPut({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then(() => {
        Message.success('删除成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 删除
 */
// export const delD =  function delD({ url,params = {}, isPost = true } = {}) {
//   request({
//     url,
//     method: 'DELETE',
//     params
//   }).then(e => {
//     if (e.code == 200) {
//       if (this.total%10 === 1 || this.total%20 === 1 || this.total%30 === 1 || this.total%40 === 1) {
//         this.current--;
//       }
//       Message.success('删除成功')
//       isPost && getList.call(this).then(() => {
//         console.log(this.total)
//         console.log(this.current)
//         console.log(this.isPage)
//         console.log(this.loading)
//         console.log(this.params)
//         console.log(this.paramsValue)
//         console.log(this.listUrl)
//         console.log(this.pageSize)
//       })
//     } else {
//       Message.error(e.msg)
//     }
//   })
// }

/**
 * 编辑模式
 */
export const edit = function edit({ url, params = {}, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const editPost = function editPost({ url, params = {}, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const editGET = function editGET({ url, params = {} } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    getAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 详情编辑模式
 */
export const detailEdit = function detailEdit({ url, params = {} } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        this.$router.back()
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 开启或关闭
 */
export const switchEnable = function toggerEnable({ url, params = {} } = {}) {
  return new Promise(resolve => {
    request({ url, params, method: 'GET' }).then(e => {
      if (e.code == 200) {
        Message.success('切换成功')
        getList.call(this)
        resolve()
      } else {
        Message.error(e.message)
      }
    })
  })
}

/**
 * 获取对应type类型
 */
export const getType = function getType({ url = this.typeUrl, params = {} } = {}) {
  request({
    url,
    method: 'GET',
    params: {
      ...params
    }
  }).then(e => {
    this.typeUrl = url
    this.type = e.result.map(e => ({ name: e.typeName || e.name, value: e.id }))
  })
}

/**
 * 获取对应分页type类型
 */
export const getPageType = function getPageType({ url, params = {} } = {}) {
  request({
    url,
    method: 'GET',
    params: {
      size: 1000,
      ...params
    }
  }).then(e => {
    this.typeUrl = url
    this.type = e.result.records.map(e => ({ name: e.typeName, value: e.id }))
  })
}

/**
 * 获取详情
 */
export const getDetail = function getDetail({ url, host = '/api/' } = {}) {
  return new Promise(resolve => {
    getAction('/api/' + url, {}, host).then(e => {
      this.detail = e.data
      resolve(e.data)
    })
  })
}

export const getChildren = function getChildren(children) {
  return children.map(e => {
    return {
      ...e,
      label: e.name,
      value: String(e.id),
      children: e.children ? getChildren(e.children) : []
    }
  })
}

/**
 * 获取级联数据类型
 */
export const getCascaderType = function getCascaderType({ url, key = 'cascaderType' } = {}) {
  request({
    url
  }).then(e => {
    this[key] = getChildren(e.result)
  })
}

/**
 * 获取区域数据
 */
export const getRegionType = function getCascaderType({ url, key = 'regionType', host = '' } = {}) {
  getAction(url, {}, host).then(e => {
    this[key] = getChildren(e.data)
  })
}

/**
 * 取消接口
 */
export const cancel = function cancel({ url, params }) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('取消成功')
        getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const replyPost = function editPost({ url, params = {} } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('已回复')
        getList.call(this)
        resolve()
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 获取所有标签 主体
 */
export const getLabelGroup = async function getLabelGroup({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then(e => {
        if (Array.isArray(e.data) && e.data.length > 0) {
          this.labelGroup = e.data
          resolve()
        } else {
          reject()
        }
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 获取所有标签 code
 */
export const getLabelCodeGroup = async function getLabelCodeGroup({ url, params = {} } = {}) {
  const all = await Promise.all(params.codes.map(e => getAction(url + `?busId=${params.id}&code=${e.key}`)))
  let arr1 = all.map(e => ({
    ...e.data
  }))
  this.labelGroup = arr1
}

/**
 * 搜索-分页
 */
export const searchPage = function search({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then(e => {
        if (e.code == 200) {
          resolve(e.data.records)
        } else {
          resolve([])
        }
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 搜索
 */
export const search = function search({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then(e => {
        if (e.code == 200) {
          resolve(e.data)
        } else {
          resolve([])
        }
      })
      .catch(e => (this.loading = false || (reject && reject(e))))
  })
}

export default {
  create,
  cancel,
  getList,
  getTree,
  del,
  edit,
  editPost,
  detailEdit,
  getState,
  getType,
  getDetail,
  getPageType,
  switchEnable,
  getCascaderType,
  getRegionType,
  getLabelGroup,
  getLabelCodeGroup,
  replyPost,
  editGET,
  createDetail,
  delPost,
  delPostArr,
  search,
  searchPage,
  delDetail,
  delPut

  // delD
}
