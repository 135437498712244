import { RouteView, BasicLayout } from '@/layouts'
import { order, configuration, goods, marketing } from '@/core/icons'

// const RouteView = {
//   name: 'RouteView',
//   render: h => h('router-view')
// }

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/infoMain/main',
    children: [
      {
        path: '/userinforsetting/personalsetting',
        name: 'personalsetting',
        hidden: true,
        component: () => import('@/views/userinforsetting/personalsetting/index'),
        meta: {
          title: '个人信息设置',
          keepAlive: false,
          permission: ['dashboard']
        }
      },
      {
        path: '/infoMain',
        name: 'infoMain',
        redirect: '/infoMain/main',
        component: RouteView,
        meta: {
          title: '信息维护',
          keepAlive: false,
          icon: order,
          permission: ['dashboard']
        },
        children: [
          {
            path: '/infoMain/main',
            name: 'main',
            component: () => import('@/views/infoMain/main'),
            meta: {
              title: '主体信息',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/infoMain/mainDetail',
            name: 'mainDetail',
            hidden: true,
            component: () => import('@/views/infoMain/main/detail'),
            meta: {
              title: '主体信息添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/infoMain/distriBution',
            name: 'distriBution',
            hidden: true,
            component: () => import('@/views/infoMain/main/distriBution'),
            meta: {
              title: '分配用户',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/infoMain/distriButionUser',
            name: 'distriButionUser',
            hidden: true,
            component: () => import('@/views/infoMain/main/distriButionUser'),
            meta: {
              title: '分配添加',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/infoMain/institutions',
            name: 'institutions',
            component: () => import('@/views/infoMain/institutions'),
            meta: {
              title: '机构管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/infoMain/institutionsDetail',
            name: 'institutionsDetail',
            hidden: true,
            component: () => import('@/views/infoMain/institutions/detail'),
            meta: {
              title: '机构管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/tenantManage/tenant',
            name: 'tenant',
            component: () => import('@/views/tenantManage/tenant'),
            meta: {
              title: '租户管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/tenantManage/tenantDetail',
            name: 'tenantDetail',
            hidden: true,
            component: () => import('@/views/tenantManage/tenant/detail'),
            meta: {
              title: '租户管理详情',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/tenantManage/assignusers',
            hidden: true,
            name: 'assignusers',
            component: () => import('@/views/tenantManage/assignusers'),
            meta: {
              title: '分配用户',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/tenantManage/assignusersDetail',
            hidden: true,
            name: 'assignusersDetail',
            component: () => import('@/views/tenantManage/assignusers/detail'),
            meta: {
              title: '添加用户',
              keepAlive: false,
              permission: ['dashboard']
            }
          }
        ]
      },
      {
        path: '/rights',
        name: 'rights',
        redirect: '/rights/role',
        component: RouteView,
        meta: {
          title: '权限管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard']
        },
        children: [
          {
            path: '/rights/role',
            name: 'role',
            component: () => import('@/views/rights/role'),
            meta: {
              title: '角色管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/rights/roleDetail',
            name: 'roleDetail',
            hidden: true,
            component: () => import('@/views/rights/role/detail'),
            meta: {
              title: '角色管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/rights/user/detailAdd',
            name: 'detailAdd',
            hidden: true,
            component: () => import('@/views/rights/user/bodyMain/detailAdd'),
            meta: {
              title: '用户管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/rights/user/bodyMain',
            name: 'bodyMain',
            component: () => import('@/views/rights/user/bodyMain'),
            meta: {
              title: '用户管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          }
          // #region
          // {
          //   path: '/rights/user',
          //   name: 'user',
          //   redirect: '/rights/user/consumers',
          //   component: RouteView,
          //   meta: {
          //     title: '用户管理',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   },
          //   children: [
          // {
          //   path: '/rights/user/consumers',
          //   name: 'consumers',
          //   component: () => import('@/views/rights/user/consumers'),
          //   meta: {
          //     title: '消费者',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // },
          // {
          //   path: '/rights/user/consumersDetail',
          //   name: 'consumersDetail',
          //   hidden: true,
          //   component: () => import('@/views/rights/user/consumers/detail'),
          //   meta: {
          //     title: '用户消费者添加/编辑',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // },
          // {
          //   path: '/rights/user/bodyMainDetail',
          //   name: 'bodyMainDetail',
          //   hidden: true,
          //   component: () => import('@/views/rights/user/bodyMain/detail'),
          //   meta: {
          //     title: '用户主体添加/编辑',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // },
          // {
          //   path: '/rights/user/government',
          //   name: 'government',
          //   component: () => import('@/views/rights/user/government'),
          //   meta: {
          //     title: '政府及运营',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // },
          // {
          //   path: '/rights/user/governmentDetail',
          //   name: 'governmentDetail',
          //   hidden: true,
          //   component: () => import('@/views/rights/user/government/detail'),
          //   meta: {
          //     title: '政府及运营添加/编辑',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // }
          //   ]
          // },
          // #endregion
        ]
      },
      {
        path: '/system',
        name: 'system',
        redirect: '/system/application',
        component: RouteView,
        meta: {
          title: '系统设置',
          keepAlive: false,
          icon: marketing,
          permission: ['dashboard']
        },
        children: [
          {
            path: '/system/application',
            name: 'application',
            component: () => import('@/views/system/application'),
            meta: {
              title: '应用管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/applicationDetail',
            name: 'applicationDetail',
            hidden: true,
            component: () => import('@/views/system/application/detail'),
            meta: {
              title: '应用管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/menu',
            name: 'menu',
            component: () => import('@/views/system/menu'),
            meta: {
              title: '菜单管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/menuDetail',
            name: 'menuDetail',
            hidden: true,
            component: () => import('@/views/system/menu/detail'),
            meta: {
              title: '菜单管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/publicParams',
            name: 'publicParams',
            component: () => import('@/views/system/publicParams'),
            meta: {
              title: '公共参数',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/publicParamsDetail',
            name: 'publicParamsDetail',
            hidden: true,
            component: () => import('@/views/system/publicParams/detail'),
            meta: {
              title: '公共参数添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/dictionary',
            name: 'dictionary',
            component: () => import('@/views/system/dictionary'),
            meta: {
              title: '字典管理',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/dictionaryDetail',
            name: 'dictionaryDetail',
            hidden: true,
            component: () => import('@/views/system/dictionary/detail'),
            meta: {
              title: '字典管理添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/dictionaryChildren',
            name: 'dictionaryChildren',
            hidden: true,
            component: () => import('@/views/system/dictionary/dictionaryChildren'),
            meta: {
              title: '字典管理子级添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/routing',
            name: 'routing',
            component: () => import('@/views/system/routing'),
            meta: {
              title: '路由',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/routingDetail',
            name: 'routingDetail',
            hidden: true,
            component: () => import('@/views/system/routing/detail'),
            meta: {
              title: '路由添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/theClient',
            name: 'theClient',
            component: () => import('@/views/system/theClient'),
            meta: {
              title: '客户端',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/system/theClientDetail',
            name: 'theClientDetail',
            hidden: true,
            component: () => import('@/views/system/theClient/detail'),
            meta: {
              title: '客户端添加/编辑',
              keepAlive: false,
              permission: ['dashboard']
            }
          }
        ]
      },
      {
        path: '/monitoring',
        name: 'monitoring',
        redirect: '/monitoring/logLog',
        component: RouteView,
        meta: {
          title: '系统监控',
          keepAlive: false,
          icon: configuration,
          permission: ['dashboard']
        },
        children: [
          {
            path: '/monitoring/loginLock',
            name: 'loginLock',
            component: () => import('@/views/monitoring/loginLock'),
            meta: {
              title: '登录锁定',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/monitoring/logLog',
            name: 'logLog',
            component: () => import('@/views/monitoring/logLog'),
            meta: {
              title: '登录日志',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/monitoring/logLogDetail',
            name: 'logLogDetail',
            hidden: true,
            component: () => import('@/views/monitoring/logLog/detail'),
            meta: {
              title: '登录日志详情',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/monitoring/operationLog',
            name: 'operationLog',
            component: () => import('@/views/monitoring/operationLog'),
            meta: {
              title: '操作日志',
              keepAlive: false,
              permission: ['dashboard']
            }
          },
          {
            path: '/monitoring/operationLogDetail',
            name: 'operationLogDetail',
            hidden: true,
            component: () => import('@/views/monitoring/operationLog/detail'),
            meta: {
              title: '操作日志详情',
              keepAlive: false,
              permission: ['dashboard']
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: '404',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: RouteView,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: '/personalsettingDetail',
        name: 'personalsettingDetail',
        component: () => import(/* webpackChunkName: "user" */ '@/views/userinforsetting/personalsetting/detail')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
