<script>
export default {
  props: {
    value: {
      type: Array || String,
      default: null
    }
  },
  methods: {
    renderItem(item) {
      return <img src={item} />
    },
    getValue() {
      if (typeof this.value == 'string') {
        return this.value.split(',')
      } else if (Array.isArray(this.value)) {
        return this.value
      } else {
        return []
      }
    }
  },
  render() {
    return (
      <div class="image-group">
        {this.getValue().map(e => {
          return this.renderItem(e)
        })}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.image-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 155px;
    height: 155px;
    border-radius: 5px;
    margin-right: 20px;
  }
}
</style>
