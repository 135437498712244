<script>
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => []
    },
    getValue: {
      type: Function,
      default: null
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  methods: {
    getData() {
      if (this.item.keys) {
        return this.item.keys.map(e => {
          const value = this.getValue({ key: e })
          if (value) {
            return moment(value)
          } else {
            return value
          }
        })
      } else {
        return this.value
      }
    },
    onChange(times) {
      if (this.item.keys && times.length > 0) {
        this.item.keys.forEach((e, i) => {
          this.setValue(e, moment(times[i]).format('YYYY-MM-DD'))
        })
      } else {
        this.$emit('change', times)
      }
    }
  },
  render() {
    return (
      <DatePicker.RangePicker
        allowClear={false}
        onChange={this.onChange}
        value={this.getData()}
        style={{ width: '100%' }}
      ></DatePicker.RangePicker>
    )
  }
}
</script>
